.page__about-fond {
  padding: 10px;
  margin-top: 40px;

  display: flex;
  justify-content: space-around;

  border-radius: 8px;

  @media (max-width: 580px) {
    display: block;
    margin: 0 0 10px 0;
    border-bottom: 1px solid #fff;
  }

  background-image: url("../../assets/img/bg_main.jpg");
  background-position: bottom;

  .about-fond--column {
    box-shadow: 8px 13px 8px 0px rgba(34, 60, 80, 0.2);
    background: #fff;
    border-radius: 15px;

    margin: 0 2px;

    padding: 10px;
    flex: 0 1 700px;

    .fond--tasks--title {
      font-size: 24px;
      font-family: "Roboto Condensed";
      color: #4f4f4f;
      margin-bottom: 10px;
    }
  }

  .fond--tasks {
    ul li {
      line-height: 30px;
    }
    @media (max-width: 580px) {
      margin-bottom: 10px;
    }
  }
  .fond--purpose {
    p {
      line-height: 30px;
      padding: 0;
    }
  }
}

///////////

.structure--fond {
  margin-top: 60px;

  background-image: url("../../assets/img/grey-arrow-left.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 30%;

  @media (max-width: 1200px) {
    background-size: 100%;
  }
}
.structure--fond--rows {
  display: flex;
  justify-content: space-around;

  @media (max-width: 800px) {
    display: block;
    text-align: center;
  }
}

.structure__table--one {
  @media (max-width: 580px) {
    margin-bottom: 30px;
  }
}

table {
  flex: 0 1 40%;
  border-top: 7px solid rgb(89, 89, 89);
  border-collapse: collapse;
  text-align: center;
  border: 1px solid #dddddd;

  @media (max-width: 580px) {
    width: 100%;
    margin: 0 auto;
  }
}

td {
  text-align: center;
  padding: 10px;
  border: 3px solid rgb(121, 121, 121);
  color: #000000;
}

.pad {
  padding: 0 !important;
}

.structure--fond--title {
  font-size: 20px;
  font-family: "Roboto Condensed";
  color: #000000;
}

////////////////

.list-of-donors {
  text-align: center;
  background-color: #fff;

  border-top: 1px solid #565656;
  border-bottom: 1px solid #565656;

  @media (max-width: 868px) {
    background-image: none;
  }
  .list-of-donors--title {
    margin: 18px 0;
    font-size: 24px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
  }
  th,
  td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
  }
  th {
    background-color: #f4f4f4;
  }

  .list-of-donors-row {
    // display: flex;
    // justify-content: space-around;

    @media (max-width: 580px) {
      display: block;
    }

    // border: 1px solid red;

    margin: 20px 0;
    padding: 20px 0;

    .list-of-donors-column {
      flex: 0 1 40%;

      border: 1px solid rgb(58, 17, 107);

      margin: 20px 5px;
      padding: 20px 5px;
      @media (max-width: 580px) {
        border: none;
      }
      .list-of-donors__individuals--title,
      .list-of-donors__legal-entities--title {
        font-weight: 700;
        font-size: 20px;
      }
      p {
        font-size: 17px;
        font-weight: 600;
      }
    }
  }
}

.documents {
  text-align: center;
}

.documents--title-one {
  font-size: 26px;
  margin: 20px 0;
}
.doc_info {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid #c4b8b8;
  padding: 7px 30px;
  cursor: pointer;
  .doc_name {
    h3 {
      color: #140d75;
    }
  }
}

.doc_open a span {
  color: #ff0000;
  font-weight: bold;
  border: 1px solid #ff0000;
  padding: 2px;
}

abbr {
  cursor: pointer;
  color: #140d75;
}
