.main__founder {
  display: flex;

  background: #f0f0f0;

  padding: 60px 5px;

  //   border: 3px solid #000;

  @media (max-width: 768px) {
    display: block;
  }

  @media (max-width: 580px) {
    padding: 30px 5px;
  }
}

.founder-column {
  flex-basis: 50%;
}

.founder-title {
  padding: 0 10px;
  text-align: center;

  //   border: 1px solid #d20404;

  @media (max-width: 580px) {
    margin-bottom: 10px;
    padding: 0;
  }
}

.founded__img {
  align-self: center;
  text-align: center;
  img {
    border-radius: 15px;
    max-width: 100%;
  }
}

.founder-title h4,
h3,
p {
  padding: 12px;
}

.founder-title h4 {
  color: #000;
}

.founder-title p {
  font-size: 16px;
  line-height: 28px;
}
