@import "./variables.scss";
@import "libs/normalize";

@import "./components/header";
@import "./components/home";
@import "./components/aboutFond";
@import "./components/founded.scss";
@import "./components/block-video.scss";
@import "./components/footer.scss";

// pages

@import "./components/pageaboutfond.scss";

.wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;

  background-color: #ffffff;
  max-width: 100%;
  .main {
    margin-top: 100px;
    border-top: 1px solid #727272;

    flex: 1 1 auto;

    @media (max-width: 580px) {
      margin-top: 10px;
    }
  }
}

.content {
  max-width: 1600px;
  margin: 20px auto;

  padding-bottom: 30px;
}
