.header {
  position: fixed;
  width: 100%;
  background: #fff;
  z-index: 1000;
  margin-bottom: 0;
  @media (max-width: 580px) {
    display: contents;
  }
  &__navbar-nav {
    width: 80%;
    margin: auto;

    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
      height: 70px;
    }
    @media (max-width: 580px) {
      height: 190px;
      display: block;
      text-align: center;
    }

    li {
      margin-right: 30px;
      @media (max-width: 960px) {
        margin-right: 10px;
      }
      .link {
        display: inline-block;
        padding: 5% 18px;
        white-space: nowrap;
        font-size: 18px;
        @media (max-width: 720px) {
          font-size: 15px;
        }
        @media (max-width: 580px) {
          padding: 2% 18px;
        }
        .navbar_span {
          color: $white;
          cursor: pointer;
          color: #504f4f;
        }
      }
    }

    .header__requisites {
      border-radius: 5px;
      @media (max-width: 580px) {
        margin-top: 10px;
      }

      background: -webkit-linear-gradient(
        top left,
        #f2152f,
        #ce052f
      ) !important;
      .button--project {
        .navbar_span {
          color: #fff;
        }
      }
    }
  }
}
