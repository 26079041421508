.footer {
  background-color: #363636;
  padding: 20px 10px;

  .container {
    max-width: 1600px;
    margin: auto;

    .footer__row {
      display: flex;
      justify-content: space-between;
      @media (max-width: 580px) {
        display: block;
      }

      .footer__column {
        flex: 0 1 700px;
        @media (max-width: 580px) {
          padding: 10px;
        }

        .footer--title {
          margin-bottom: 10px;
          color: #d8d8d8;
          font-family: "Lato", Sans-serif;
          font-size: 20px;
        }

        .footer__contacts--phone p,
        .footer_recs--pad p {
          color: #d8d8d8;
          padding: 8px !important;
        }
      }
    }
  }
  .developer {
    text-align: end;
    a {
      color: #6f6f6f;
      font-size: 12px;
    }
  }
}
