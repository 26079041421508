.main__bg {
  background: #000;
  @media (max-width: 580px) {
  }
}
.main__bg img {
  opacity: 0.9;
}
.main__text {
  width: 40%;
  position: absolute;
  text-align: center;
  top: 50%;
  right: 5%;
  z-index: 1000;

  @media (max-width: 1500px) {
    top: 40%;
  }

  @media (max-width: 1240px) {
    top: 30%;
  }

  @media (max-width: 1024px) {
    top: 22%;
  }

  @media (max-width: 768px) {
    top: 18%;
  }

  @media (max-width: 580px) {
    top: 35%;
  }
}

.main__title {
  color: #fff;
  font-size: 40px;
  font-weight: 600;
  font-family: HelveticaNeueCyr, muli, sans-serif;
  margin-bottom: 30px;

  @media (max-width: 1500px) {
    font-size: 34px;
  }

  @media (max-width: 1240px) {
    font-size: 30px;
  }

  @media (max-width: 1024px) {
    font-size: 22px;
  }

  @media (max-width: 768px) {
    font-size: 18px;
  }

  @media (max-width: 580px) {
    font-size: 12px;
    margin-bottom: 15px;
  }
}

.main__desc {
  font-family: HelveticaNeueCyr, open sans, sans-serif;
  font-size: 30px;
  color: #fff;
  background-repeat: no-repeat;
  line-height: 34px;

  @media (max-width: 1500px) {
    font-size: 28px;
  }

  @media (max-width: 1240px) {
    font-size: 22px;
  }

  @media (max-width: 1024px) {
    font-size: 16px;
    line-height: 18px;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 14px;
  }

  @media (max-width: 580px) {
    font-size: 11px;
  }
  span {
    color: #fff;
    display: inline-block;
    margin-top: 2%;
  }
}
