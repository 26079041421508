.main__about-fond {
  width: 98%;
  margin: auto;
  margin-top: 16px;
  display: flex;

  @media (max-width: 580px) {
    display: block;
    margin-top: 10px;
    margin-bottom: 16px;
    width: 100%;
  }
}

.main__about_left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 85%;

  background: rgb(194, 155, 105);

  @media (max-width: 580px) {
    margin: 10px 0;
    padding: 15px 0;
    width: 100%;
  }
}

.about_left__title {
  color: #fff;
  margin-bottom: 10px;
  font-size: 25px;
}

.about_left__desc {
  color: #fff;
  margin-bottom: 10px;
  padding: 20px 0;
  font-size: 18px;
  width: 70%;
}
.about_left__button {
  color: #fff;
  padding: 10px;
  border: 1px solid #d6d6d6;
  @media (max-width: 580px) {
    margin-bottom: 10px;
  }
}

.about_left__button_link {
  color: #fff;
}

.main__about_right {
  text-align: center;
  width: 100%;

  @media (max-width: 580px) {
    text-align: center;
    width: 100%;
  }
}

.main__man_foto_desc {
  text-align: center;
}
